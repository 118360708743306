import React from 'react';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import blogBanner from '../images/blogBanner.png';
import 'normalize.css';
import Layout from '../components/Layout';
import csrPolicyPdf from '../images/CSR_Policy_Nuclei.pdf';

const CorporateGovernance = ({ data }) => {
  return (
    <>
      <SEO
        title='Nuclei | Corporate Governance'
        description='CDNA Technlogies completely endorses reliability. It is committed to conducting business in a true, fair, and ethical manner and takes up the responsibility to create a good impact in the society it belongs.'
        bannerImage={blogBanner}
        pathname='corporate-governance'
      />
      <Layout isWithoutHero={true}>
        <Helmet>
          <link
            rel='stylesheet'
            href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css'
          />
        </Helmet>
        <section className='section columns is-multiline blog-grid-card'>
          <div
            className='social-resp-container'
            style={{ textAlign: 'justify' }}
          >
            <h1>Corporate Social Responsibility</h1>
            <hr />
            <p>
              Corporate Social Responsibility (CSR) is a public-spirited cause
              that has been well introduced by the Companies Act 2013. Through
              CSR there is a formation of a dynamic relationship between a
              company on one hand and the society and environment on the other.
              CSR is traditionally driven by a moral obligation and
              philanthropic spirit which resonates with the policy of the
              Company.
            </p>
            <h5 className='custom-font-bold'>Policy Objective</h5>
            <p>
              The objective of the CSR Policy (“Policy”) is to define the
              company&apos;s vision and lay down the guiding principles in
              undertaking various Programs and projects by or on behalf of the
              company relating to CSR within the meaning of section 135 of the
              Companies Act, 2013 read with Schedule VII of the Act and the CSR
              Policy Rules 2014. ("Rules").
            </p>
            <h5 className='custom-font-bold'>CSR Vision</h5>
            <ol className='ordered-list'>
              <li>
                The Company completely endorses reliability. It is committed to
                conducting business in a true, fair, and ethical manner and
                takes up the responsibility to create a good impact in the
                society it belongs.
              </li>
              <li>
                The Company is committed to contributing positively to society.
                The Company believes that giving back to society through CSR
                activities is its moral duty.
              </li>
              <li>
                The Company aims to fulfill the requirements laid down under the
                Companies Act, 2013, and act diligently to comply with all its
                Rules and Regulations on CSR.
              </li>
            </ol>
          </div>
          <div>
            <h5 className='custom-font-bold'>
              Corporate Social Responsibility Committee:
            </h5>
            <div className='csr-comittee'>
              <div style={{ marginTop: '1rem' }}>
                <p>
                  <strong>Name</strong>
                </p>
                <p>
                  <strong>Designation</strong>
                </p>
              </div>
              <div>
                <p>Mr. Ankur Joshi</p>
                <p>Executive Director</p>
              </div>
              <div>
                <p>Mr. Adarsh Pandey</p>
                <p>Executive Director</p>
              </div>
            </div>
            <h5 className='custom-font-bold'>
              Corporate Social Responsibility Projects approved by the board for
              FY 22-23:
            </h5>
            <ol className='ordered-list'>
              <li>Medical treatment of the dog rescues.</li>
              <li>
                The rehabilitation center, Sterilization facilities, Training
                Vets and Vet Students, Awareness among villagers, Animal Shelter
                for abandoned and aged animals, emergency services for Animals.
              </li>
              <li>Setting up homes and hostels for orphans.</li>
              <li>
                Promoting education, including special education and employment
                enhancing vocation skills especially among children, women,
                elderly, and the differently abled and livelihood enhancement
                project.
              </li>
            </ol>
            <div>
              <a
                className='button download-btn'
                href={csrPolicyPdf}
                target='_blank'
              >
                View CSR Policy
              </a>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default CorporateGovernance;
